var $class="se2--footer-bottom-links",$name="FooterBottomLinks",$path="app/components/FooterBottomLinks/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import 'app/partials/image';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import './partials/bottomBar';

export default shell.registerComponent($this, (
  { addEventListener,
    mount },
) => {
  mount((element) => {
    const footerTopClassName = 'footer-top';

    const announceFooterHeight = () => {
      element.classList.remove(footerTopClassName);
      if (isMobileBreakpoint()) return;
      if (
        Math.round(element.getBoundingClientRect().bottom)
        < document.documentElement.getBoundingClientRect().height
      ) {
        element.classList.add(footerTopClassName);
      }
    };

    addEventListener(window, 'resize', announceFooterHeight);
    announceFooterHeight();
  });
});
