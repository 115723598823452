var $class="se2--footer-bottom-links--bottom-bar",$name="FooterBottomLinks/bottomBar",$path="app/components/FooterBottomLinks/partials/bottomBar/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import { cookiePrivacyLinkClassName } from 'app/components/FooterBottomLinks/config';

function openCookiePrivacy(event) {
  event.preventDefault();
  window.tC?.privacyCenter?.showPrivacyCenter?.();
}

export default shell.registerPartial($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const cookiePrivacyLinkElement = element
      .querySelector(`.${cookiePrivacyLinkClassName}`);
    addEventListener(cookiePrivacyLinkElement, 'click', openCookiePrivacy);
  });
});
